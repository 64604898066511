<template>
  <div class="container">
    <div class="row flex-center mt-5 py-4 text-center">
      <div class="col-sm-12 col-md-12 col-lg-10 col-xxl-10">
        <div class="card">
          <div class="card-body p-4 p-sm-5">
            <div class="display-1 text-200 fs-error">СКОРО!</div>
            <p class="lead mt-4 text-800 text-sans-serif font-weight-semi-bold">
              Информация скоро появится, наберитесь терпения...
            </p>
            <hr />
            <p>
              Мы усердно работаем над расширением нашего функционала!  
            </p>
            <router-link class="btn btn-primary btn-sm mt-3" to="/dashboard">
              <span class="fas fa-chart-pie mr-2"></span>
              на основную
            </router-link>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {

}
</script>

<style lang="scss" scoped>
.fs-error {
  font-size: 8rem;
}

@media (max-width: 950px) {
  .fs-error {
    font-size: 5rem !important;
  }
}

@media (max-width: 500px) {
  .fs-error {
    font-size: 3rem !important;
  }
}

</style>